import React, { useContext, useState } from "react";
import { useIsAuthenticated } from "~/hooks/context-hook";
import AuthCheckDialog from "~/components/dialogs/auth-check-dialog";

export interface AuthCheckProps {
  text: string;
  redirectUrl: string;
  type: string;
}

export function AuthCheckProvider({ children }: React.PropsWithChildren) {
  const [authCheck, setAuthCheck] = useState<{
    text: string;
    redirectUrl: string;
    type: string;
  }>({ text: "", redirectUrl: "", type: "" });
  return (
    <>
      <AuthCheckContext.Provider
        value={{
          setAuthCheck,
        }}
      >
        {children}
      </AuthCheckContext.Provider>
      <AuthCheckDialog
        dialogProps={authCheck}
        close={() => {
          setAuthCheck({ text: "", redirectUrl: "", type: "" });
        }}
      />
    </>
  );
}
export const AuthCheckContext = React.createContext<{
  setAuthCheck: (props: AuthCheckProps) => any;
}>({
  setAuthCheck: () => {},
});

export const currentSpaceUniqueName = (request: Request) => {
  const url = new URL(request.url);
  const split = url.host.split(".");
  if (!split || split.length === 0) {
    throw "Something went wrong";
  }
  return split[0];
};
export const useAuthCheck = () => {
  const authenticated = useIsAuthenticated();
  const setAuthCheck = useContext(AuthCheckContext).setAuthCheck;
  return (text: string, redirectUrl?: string, type?: string) => {
    if (!authenticated) {
      setAuthCheck({
        text,
        redirectUrl: encodeURIComponent(redirectUrl || location.href),
        type: type || "",
      });
    } else {
      setAuthCheck({ text: "", redirectUrl: "", type: "" });
    }
    return authenticated;
  };
};
export default useAuthCheck;
